// extracted by mini-css-extract-plugin
export var abbFeatures = "work-module--abbFeatures--d2d6b";
export var abbHero = "work-module--abbHero--27a5f hero-module--hero--c42c6";
export var abbHeroImage = "work-module--abbHeroImage--da2e2 hero-module--image--2e636";
export var macgregorFeatures = "work-module--macgregorFeatures--18e09";
export var macgregorHero = "work-module--macgregorHero--0e93c hero-module--hero--c42c6";
export var macgregorHeroImage = "work-module--macgregorHeroImage--33f47 hero-module--image--2e636";
export var vareFeatures = "work-module--vareFeatures--f34af";
export var vareHero = "work-module--vareHero--74523 hero-module--hero--c42c6";
export var vareHeroImage = "work-module--vareHeroImage--22fdb hero-module--image--2e636";
export var variousHero = "work-module--variousHero--18707 hero-module--hero--c42c6";
export var variousHeroImage = "work-module--variousHeroImage--2e5e2 hero-module--image--2e636";
export var workCases = "work-module--workCases--44ae0";
export var workHero = "work-module--workHero--1971b hero-module--hero--c42c6";
export var workHeroImage = "work-module--workHeroImage--5daeb hero-module--image--2e636";